import { SubscriptionWithVersion } from '../../../../../hooks/useProductSubscriptions';
import { StateCreator } from 'zustand';
import { DeploymentsStore } from './deployments-store';
import { SUBSCRIPTION_PROJECT_ANNOTATION } from 'custom-annotations';
import { DeploymentStatus } from '../DeploymentAccordion/useDeploymentStatus';

type State = {
  subscriptionsWithVersions: SubscriptionWithVersion[];
  filteredSubscriptionsWithVersions: SubscriptionWithVersion[];
  statuses: Record<string, DeploymentStatus>;
};

type Actions = {
  setSubscriptionsWithVersions: (
    subscriptions: SubscriptionWithVersion[],
  ) => void;
  filterSubscriptionWithVersions: () => void;
  setProductStatus: (productStatus: Record<string, DeploymentStatus>) => void;
};

export type SubscriptionsSlice = State & Actions;

export const createSubscriptionsSlice: StateCreator<
  DeploymentsStore,
  [],
  [],
  SubscriptionsSlice
> = (set, get) => ({
  subscriptionsWithVersions: [],
  filteredSubscriptionsWithVersions: [],
  statuses: {},
  setSubscriptionsWithVersions: (subscriptions: SubscriptionWithVersion[]) => {
    const projects = subscriptions
      .map(
        subscription =>
          subscription.metadata.annotations?.[SUBSCRIPTION_PROJECT_ANNOTATION],
      )
      .filter((project): project is string => Boolean(project));

    get().setProjects(projects);

    set(() => ({ subscriptionsWithVersions: subscriptions }));
    get().filterSubscriptionWithVersions();
  },
  filterSubscriptionWithVersions: () =>
    set(state => {
      const subscriptionsWithVersions = state.subscriptionsWithVersions;

      const filteredSubscriptionsWithVersions =
        subscriptionsWithVersions.reduce<SubscriptionWithVersion[]>(
          (acc, subscription) => {
            const matchSearchQuery = subscription.metadata.title
              ?.toLowerCase()
              .includes(state.searchQuery.toLowerCase());

            const hasProject = state.projectFilter.includes(
              subscription.metadata.annotations?.[
                SUBSCRIPTION_PROJECT_ANNOTATION
              ] ?? '',
            );

            if (matchSearchQuery && hasProject) {
              acc.push(subscription);
            }

            return acc;
          },
          [],
        );

      return { filteredSubscriptionsWithVersions };
    }),
  setProductStatus: (productStatus: Record<string, DeploymentStatus>) =>
    set(state => ({
      statuses: {
        ...state.statuses,
        ...productStatus,
      },
    })),
});
