import { SubscriptionWithVersion } from '../../../../../../hooks/useProductSubscriptions';
import {
  catalogApiRef,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import {
  Entity,
  RELATION_DEPENDS_ON,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Table,
  IconText,
} from '@internal/backstage-plugin-adeo-core-components-react';
import { getEntityIcon } from '../../../../../../../Root/sidebar/recently-visited/utils';
import { DeploymentAccordionStatus } from '../AccordionHeader/DeploymentAccordionStatus';
import { makeStyles } from '@material-ui/core';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { ARGO_APP_NAME_ANNOTATION } from 'custom-annotations';
import { DeploymentStatus } from '../useDeploymentStatus';
import { useDeploymentsStore } from '../../store/deployments-store';

const useDeploymentAccordionStyles = makeStyles(theme => ({
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.3)}px !important`,
  },
}));

interface DeploymentAccordionContentProps {
  subscriptionWithVersion: SubscriptionWithVersion;
  statuses?: Record<string, DeploymentStatus>;
}

export const DeploymentAccordionContent = ({
  subscriptionWithVersion,
  statuses,
}: DeploymentAccordionContentProps) => {
  const classes = useDeploymentAccordionStyles();
  const openDrawer = useDeploymentsStore(state => state.openDrawer);

  const productVersionDependsOnRelationRefs = useMemo(
    () =>
      getEntityRelations(subscriptionWithVersion.version, RELATION_DEPENDS_ON),
    [subscriptionWithVersion],
  );
  const [componentsAndResources, setComponentsAndResources] = useState<
    Entity[]
  >([]);

  const catalogApi = useApi(catalogApiRef);

  useEffect(() => {
    const getComponentsAndResource = async () => {
      const { items } = await catalogApi.getEntitiesByRefs({
        entityRefs: productVersionDependsOnRelationRefs.map(stringifyEntityRef),
        filter: { kind: ['Component', 'Resource'] },
      });

      setComponentsAndResources(
        items.filter((item): item is Entity => Boolean(item)),
      );
    };

    getComponentsAndResource();
  }, [productVersionDependsOnRelationRefs, catalogApi]);

  const getCellClassName = useCallback(() => classes.tableCell, [classes]);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'kind',
        headerName: 'Type',
        flex: 0.4,
        renderCell: (params: GridRenderCellParams) => (
          <IconText
            Icon={getEntityIcon(params.row.entity)}
            text={params.row.entity.spec?.type}
          />
        ),
      },
      { field: 'title', headerName: 'Name', flex: 0.5 },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.5,
        renderCell: params => {
          const status =
            statuses?.[
              params.row.entity.metadata.annotations[ARGO_APP_NAME_ANNOTATION]
            ];
          return <DeploymentAccordionStatus status={status} />;
        },
      },
      { field: 'description', headerName: 'Description', flex: 1 },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 0.2,
        type: 'actions',
        getActions: params => [
          <GridActionsCellItem
            key="see-details"
            icon={<VisibilityIcon />}
            color="primary"
            label="See details"
            onClick={() => {
              openDrawer(params.row.entity, subscriptionWithVersion);
            }}
          />,
          <GridActionsCellItem
            key="actions"
            icon={<MoreHorizIcon />}
            color="primary"
            label="Actions"
            onClick={() => {}}
          />,
        ],
      },
    ],
    [openDrawer, statuses, subscriptionWithVersion],
  );

  const rows = useMemo(
    () =>
      componentsAndResources?.map(entity => ({
        id: entity.metadata.uid,
        kind: entity.kind,
        title: entity.metadata.title,
        status: 'In Progress',
        description: entity.metadata.description,
        entity,
      })) ?? [],
    [componentsAndResources],
  );

  return (
    <Table
      columns={columns}
      rows={rows}
      rowHeight={70}
      getCellClassName={getCellClassName}
      disableColumnSorting
      disableColumnResize
      disableColumnMenu
      disableRowSelectionOnClick
    />
  );
};
