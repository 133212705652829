import React, { useMemo, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import {
  Container,
  debounce,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Header } from '../header/Header';
import { HomeIcon, Inventory2SearchIcon } from 'backstage-plugin-icons-react';
import { PackageDiscoveryCard } from './PackageDiscoveryCard';
import {
  Breadcrumbs,
  BreadcrumbsItems,
} from '@internal/backstage-plugin-adeo-core-components-react';
import { useQuery } from '@tanstack/react-query';
import chunk from 'lodash.chunk';
import clamp from 'lodash.clamp';
import { Pagination } from '@material-ui/lab';

const usePackageDiscoveryStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    gap: theme.spacing(2),
    height: '100%',
    marginTop: theme.spacing(5),
  },
  entityPresentation: {
    position: 'relative',
  },
  actionButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  searchInput: {
    background: 'white',
  },
  searchIcon: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const PackageDiscovery = () => {
  const classes = usePackageDiscoveryStyles();
  const catalogApi = useApi(catalogApiRef);
  const [titleFilter, setTitleFilter] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);

  const onTitleFilterChange = debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTitleFilter(e.target.value);
    },
    300,
  );

  const { data: packages } = useQuery<Entity[]>({
    queryKey: ['package-discovery'],
    queryFn: async () => {
      const { items } = await catalogApi.getEntities({
        filter: { kind: 'Component', 'spec.type': 'package' },
        order: { field: 'metadata.title', order: 'asc' },
      });

      return items;
    },
    initialData: [],
  });

  const chunkedFilteredPackages = useMemo(() => {
    const filteredPackages = packages.filter(packageEntity =>
      packageEntity.metadata.title
        ?.toLowerCase()
        .includes(titleFilter.toLowerCase()),
    );

    return chunk(filteredPackages, 6);
  }, [packages, titleFilter]);

  const clampedPageIndex = clamp(
    currentPage - 1,
    0,
    chunkedFilteredPackages.length - 1,
  );

  const onPageChange = (_: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Header withBottomBorder>
        <Breadcrumbs>
          <BreadcrumbsItems label="Home" to="/" Icon={HomeIcon} isCompact />
          <BreadcrumbsItems
            label="Package discovery"
            Icon={Inventory2SearchIcon}
            isLast
          />
        </Breadcrumbs>
      </Header>

      <Container maxWidth="lg" className={classes.root}>
        <TextField
          variant="outlined"
          placeholder="Search a package"
          InputProps={{
            className: classes.searchInput,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            ),
          }}
          onChange={onTitleFilterChange}
        />
        {!!chunkedFilteredPackages.length && (
          <>
            <Grid container spacing={2}>
              {chunkedFilteredPackages[clampedPageIndex].map(packageEntity => (
                <PackageDiscoveryCard
                  packageEntity={packageEntity}
                  key={`${packageEntity.metadata.namespace}-${packageEntity.metadata.name}`}
                />
              ))}
            </Grid>
            <Pagination
              count={chunkedFilteredPackages.length}
              className={classes.pagination}
              onChange={onPageChange}
              page={currentPage}
            />
          </>
        )}
      </Container>
    </>
  );
};
