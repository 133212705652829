import React from 'react';
import {
  RELATION_DEPENDENCY_OF,
  ComponentEntity,
} from '@backstage/catalog-model';
import {
  entityRouteRef,
  useEntity,
  useRelatedEntities,
} from '@backstage/plugin-catalog-react';

import {
  Breadcrumbs,
  BreadcrumbsItems,
} from '@internal/backstage-plugin-adeo-core-components-react';
import {
  PackageBreadcrumbsItems,
  useProductVersionEntityBreadcrumbData,
} from './ProductVersionEntityBreadcrumb';
import {
  DeployedCodeIcon,
  HomeIcon,
  Package2Icon,
} from 'backstage-plugin-icons-react';
import { useRouteRef } from '@backstage/core-plugin-api';
import { getEntityIcon } from '../../../Root/sidebar/recently-visited/utils';

interface ControlledComponentAndResourceEntityBreadcrumbProps {
  entity: ComponentEntity;
  includeLast: boolean;
  compact: boolean;
  isExpandable: boolean;
}

const ControlledComponentAndResourceEntityBreadcrumb = ({
  entity,
  includeLast,
  compact,
  isExpandable,
}: ControlledComponentAndResourceEntityBreadcrumbProps) => {
  const { entities, loading, error } = useRelatedEntities(entity, {
    kind: 'Component',
    type: RELATION_DEPENDENCY_OF,
  });

  const productVersionEntity = entities?.find(
    ent => ent.spec?.type === 'productVersion',
  ) as ComponentEntity;
  const generateEntityRoute = useRouteRef(entityRouteRef);

  const productVersionRoute = generateEntityRoute({
    kind: 'Component',
    namespace: productVersionEntity?.metadata.namespace ?? '',
    name: productVersionEntity?.metadata.name ?? '',
  });

  const {
    loading: loadingProduct,
    error: productError,
    productEntity,
    productRoute,
  } = useProductVersionEntityBreadcrumbData(productVersionEntity);

  if (loading || error || loadingProduct || productError) {
    return null;
  }

  return (
    <Breadcrumbs isExpandable={isExpandable}>
      <BreadcrumbsItems label="Home" to="/" Icon={HomeIcon} isCompact />

      {productEntity && (
        <PackageBreadcrumbsItems
          productEntity={productEntity}
          isCompact={compact}
        />
      )}

      <BreadcrumbsItems
        label={productEntity?.metadata.title ?? ''}
        to={productRoute}
        Icon={Package2Icon}
        isCompact={compact}
      />

      <BreadcrumbsItems
        label={productVersionEntity.metadata.title ?? ''}
        to={productVersionRoute}
        Icon={DeployedCodeIcon}
      />

      {includeLast && (
        <BreadcrumbsItems
          label={entity.metadata.title ?? ''}
          Icon={getEntityIcon(entity)}
          isLast
        />
      )}
    </Breadcrumbs>
  );
};

ControlledComponentAndResourceEntityBreadcrumb.displayName =
  'ControlledComponentEntityBreadcrumb';

const ComponentAndResourceEntityBreadcrumb = () => {
  const { entity } = useEntity<ComponentEntity>();

  return (
    <ControlledComponentAndResourceEntityBreadcrumb
      entity={entity}
      includeLast
      compact={false}
      isExpandable
    />
  );
};

ComponentAndResourceEntityBreadcrumb.displayName = 'ComponentEntityBreadcrumb';

export {
  ComponentAndResourceEntityBreadcrumb,
  ControlledComponentAndResourceEntityBreadcrumb,
};
