import {
  Checkbox,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import { classNames } from '../../../../../../../utils/classNames';
import { StatusBadge } from '../StatusBadge';
import FormControl from '@material-ui/core/FormControl';
import React, { useCallback } from 'react';
import { RenderArgoStatusFilterValue } from './RenderArgoStatusFilterValue';
import { useDeploymentsStore } from '../store/deployments-store';

const useSyncStatusStyle = makeStyles(theme => ({
  formControl: {
    flex: 1,
  },
  select: {
    background: theme.palette.common.white,
  },
  selectWithChip: {
    '& > [class^="MuiSelect-root"]': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));

export const SyncStatusFilter = () => {
  const classes = useSyncStatusStyle();
  const syncStatuses = useDeploymentsStore(state => state.syncStatus);
  const syncStatusesFilter = useDeploymentsStore(
    state => state.syncStatusFilter,
  );
  const setSyncStatusesFilter = useDeploymentsStore(
    state => state.setSyncStatusesFilter,
  );

  const onStatusesChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSyncStatusesFilter(event.target.value as string[]);
  };

  const renderStatusesValue = useCallback(
    (inputStatuses: unknown) => (
      <RenderArgoStatusFilterValue
        inputStatuses={inputStatuses as string[]}
        statuses={syncStatuses}
        title="Sync"
      />
    ),
    [syncStatuses],
  );

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        labelId="sync-status-label"
        id="sync-status-select"
        value={syncStatusesFilter}
        multiple
        displayEmpty
        renderValue={renderStatusesValue}
        onChange={onStatusesChange}
        className={classNames(
          classes.select,
          setSyncStatusesFilter.length ? classes.selectWithChip : null,
        )}
        MenuProps={{
          getContentAnchorEl: null,
        }}
      >
        {syncStatuses.map(status => (
          <MenuItem key={status} value={status}>
            <Checkbox checked={syncStatusesFilter.includes(status)} />
            <ListItemText
              primary={
                <span>
                  <StatusBadge status={status} /> {status}
                </span>
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
