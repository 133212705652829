import React, {
  forwardRef,
  useContext,
  useMemo,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { EntityPresentationContext } from '../EntityPresentationContext';
import {
  useLineClamp,
  scrollHeightCssVariable,
  clientHeightCssVariable,
} from '../../../hooks';

/**
 * Props for {@link EntityPresentationDescription}.
 *
 * @public
 */
export type EntityPresentationDescriptionProps = {
  description: string;
} & React.HTMLAttributes<HTMLDivElement>;

const NUMBER_OF_DISPLAY_LINES = 2;

const useEntityPresentationDescriptionStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
    margin: theme.spacing(2),
    flexBasis: '80px',
  },
  textCollapse: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': NUMBER_OF_DISPLAY_LINES,
    '-webkit-box-orient': 'vertical',
  },
  textExpand: {
    overflow: 'hidden',
    display: 'default',
    '-webkit-box-orient': 'vertical',
  },
  animateCollapse: {
    animationName: '$collapse',
    animationDuration: '0.2s',
    animationTimingFunction: 'ease-out',
  },
  animateExpand: {
    animationName: '$expand',
    animationDuration: '0.2s',
    animationTimingFunction: 'ease-out',
  },
  '@keyframes expand': {
    from: { height: `var(${clientHeightCssVariable})` },
    to: { height: `var(${scrollHeightCssVariable})` },
  },
  '@keyframes collapse': {
    from: { height: `var(${scrollHeightCssVariable})` },
    to: { height: `var(${clientHeightCssVariable})` },
  },
}));

/**
 * Displays an entity presentation description.
 *
 * @remarks
 *
 * This component displays a description text with a read more button when the text is clamped.
 * The text can be expanded or collapsed by clicking the read more/less button. this component
 * can also display an ellipsis when the text is clamped. This component is intended to be used
 * within an {@link EntityPresentation}.
 *
 * @public
 */
export const EntityPresentationDescription = forwardRef<
  HTMLDivElement,
  EntityPresentationDescriptionProps
>((props, ref) => {
  const { description, className, ...restProps } = props;
  const { size } = useContext(EntityPresentationContext);
  const textRef = useRef<HTMLSpanElement>(null);
  const classes = useEntityPresentationDescriptionStyles();
  const { isClamped, cssVarStyle } = useLineClamp(
    textRef,
    NUMBER_OF_DISPLAY_LINES,
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const [isInAnimation, setIsInAnimation] = useState(false);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const onButtonClicked = useCallback(() => {
    setIsExpanded(prev => !prev);
    setIsInAnimation(true);
    timeoutIdRef.current = setTimeout(() => {
      setIsInAnimation(false);
    }, 200);
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, []);

  const textClasses = useMemo(() => {
    let stateClass = isExpanded ? classes.textExpand : classes.textCollapse;
    if (isInAnimation) {
      stateClass += isExpanded
        ? ` ${classes.animateExpand}`
        : ` ${classes.animateCollapse}`;
    }
    return stateClass;
  }, [isExpanded, isInAnimation, classes]);

  const buttonLabel = isExpanded ? 'Read less' : 'Read more';

  return (
    <div ref={ref} className={`${className} ${classes.root}`} {...restProps}>
      <Typography
        ref={textRef}
        variant={size === 'small' ? 'body2' : 'body1'}
        className={textClasses}
        style={cssVarStyle}
      >
        {description}
      </Typography>
      {isClamped && (
        <Button size="small" color="primary" onClick={onButtonClicked}>
          {buttonLabel}
        </Button>
      )}
    </div>
  );
});

EntityPresentationDescription.displayName = 'EntityPresentationDescription';
