import { StateCreator } from 'zustand';
import { ComponentEntity } from '@backstage/catalog-model';
import { MetricsKey } from '../../../../../../../hooks/useMetrics';
import { DeploymentStatus } from '../DeploymentAccordion/useDeploymentStatus';
import { SubscriptionWithVersion } from '../../../../../hooks/useProductSubscriptions';

type State = {
  open: boolean;
  entity?: ComponentEntity;
  subscriptionWithVersion?: SubscriptionWithVersion;
  entityMetrics?: MetricsKey[];
  status?: DeploymentStatus;
};

const initialState: State = {
  open: false,
  entity: undefined,
  subscriptionWithVersion: undefined,
};

type Actions = {
  openDrawer: (
    entity: ComponentEntity,
    subscriptionWithVersion: SubscriptionWithVersion,
  ) => void;
  closeDrawer: () => void;
};

export type DeploymentDrawerSlice = State & Actions;

export const createDeploymentDrawerSlice: StateCreator<
  DeploymentDrawerSlice
> = set => ({
  ...initialState,
  openDrawer: (
    entity: ComponentEntity,
    subscriptionWithVersion: SubscriptionWithVersion,
  ) => set({ open: true, entity, subscriptionWithVersion }),
  closeDrawer: () => set(initialState),
});
