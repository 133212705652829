import { Container, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useProductSubscriptions } from '../../../../hooks/useProductSubscriptions';
import { DeploymentAccordion } from './DeploymentAccordion/DeploymentAccordion';
import { ProductDeploymentFilters } from './ProductDeploymentFilters/ProductDeploymentFilters';
import { DeploymentDrawer } from './DeploymentDrawer/DeploymentDrawer';
import { useDeploymentsStore } from './store/deployments-store';

const useProductDeploymentsStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    gap: theme.spacing(2),
  },
}));

export const ProductDeployments = () => {
  const classes = useProductDeploymentsStyles();

  const setSearchQuery = useDeploymentsStore(state => state.setSearchQuery);
  const setSubscriptionsWithVersions = useDeploymentsStore(
    state => state.setSubscriptionsWithVersions,
  );
  const filteredSubscriptionsWithVersions = useDeploymentsStore(
    state => state.filteredSubscriptionsWithVersions,
  );
  const resetFilters = useDeploymentsStore(state => state.resetFilters);

  const { entity } = useEntity();

  const subscriptionsWithVersions = useProductSubscriptions(entity);

  useEffect(() => {
    setSubscriptionsWithVersions(subscriptionsWithVersions);
  }, [subscriptionsWithVersions, setSubscriptionsWithVersions]);

  const onSearch = (query: string) => {
    setSearchQuery(query);
  };

  // Reset filters on unmount
  useEffect(() => {
    return () => {
      resetFilters();
    };
  }, [resetFilters]);

  return (
    <>
      <Container maxWidth="lg" className={classes.root}>
        <Grid lg={12} item>
          <ProductDeploymentFilters onSearch={onSearch} />
          {filteredSubscriptionsWithVersions.map(subscription => (
            <DeploymentAccordion
              subscription={subscription}
              key={subscription.metadata.uid}
            />
          ))}
        </Grid>
      </Container>
      <DeploymentDrawer />
    </>
  );
};
