import { ControlledComponentAndResourceEntityBreadcrumb } from '../../../../../entity-layout/entity-breadcrumbs/ComponentAndResourceEntityBreadcrumb';
import { makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { getEntityIcon } from '../../../../../../Root/sidebar/recently-visited/utils';
import {
  ComponentEntity,
  Entity,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import React, { useEffect, useMemo, useRef } from 'react';
import {
  catalogApiRef,
  getEntityRelations,
  useRelatedEntities,
} from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { useDeploymentsStore } from '../store/deployments-store';

const useDrawerBreadcrumbStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  breadcrumbDivider: {
    display: 'flex',
    margin: `0 ${theme.spacing(1)}px`,
    userSelect: 'none',
  },
  formControl: {
    minWidth: '300px',
  },
  selectValue: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

const useProductVersionChildren = (entity: ComponentEntity) => {
  const [productVersionChildren, setProductVersionChildren] = React.useState<
    Entity[]
  >([]);

  const catalogApi = useApi(catalogApiRef);

  const { entities: dependencyOfRelations } = useRelatedEntities(entity, {
    kind: 'Component',
    type: RELATION_DEPENDENCY_OF,
  });

  const productVersion = dependencyOfRelations?.find(
    ent => ent.spec?.type === 'productVersion',
  ) as Entity;

  const componentDependsOn = useMemo(
    () =>
      getEntityRelations(productVersion, RELATION_DEPENDS_ON, {
        kind: 'Component',
      }),
    [productVersion],
  );
  const resourceDependsOn = useMemo(
    () =>
      getEntityRelations(productVersion, RELATION_DEPENDS_ON, {
        kind: 'Resource',
      }),
    [productVersion],
  );

  useEffect(() => {
    const fetchVersionChildren = async () => {
      const { items: children } = await catalogApi.getEntitiesByRefs({
        entityRefs: [...componentDependsOn, ...resourceDependsOn].map(
          stringifyEntityRef,
        ),
      });

      setProductVersionChildren(
        children.filter((child): child is Entity => Boolean(child)),
      );
    };

    fetchVersionChildren();
  }, [componentDependsOn, resourceDependsOn, catalogApi]);

  return productVersionChildren;
};

interface DrawerBreadcrumbProps {
  entity: ComponentEntity;
}

export const DrawerBreadcrumb = ({ entity }: DrawerBreadcrumbProps) => {
  const initialEntity = useRef(entity);

  const classes = useDrawerBreadcrumbStyles();
  const productVersionChildren = useProductVersionChildren(entity);

  const setDrawerEntity = useDeploymentsStore(state => state.openDrawer);
  const subscriptionWithVersion = useDeploymentsStore(
    state => state.subscriptionWithVersion,
  );

  const renderSelectValue = (value: unknown) => {
    if (!value) return '';

    const parsedValue: Entity = JSON.parse(value as string); // stringified Entity
    const Icon = getEntityIcon(parsedValue);

    return (
      <span className={classes.selectValue}>
        <Icon fontSize="small" />
        {parsedValue.metadata.title}
      </span>
    );
  };

  const renderSelectOption = (option: Entity) => {
    const Icon = getEntityIcon(option);
    return (
      <MenuItem value={JSON.stringify(option)} className={classes.selectValue}>
        <Icon />
        {option.metadata.title}
      </MenuItem>
    );
  };

  // at this point, subscriptionWithVersion is necessarily not undefined so these functions are safe to use
  const onSelectChange = subscriptionWithVersion
    ? (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        setDrawerEntity(
          JSON.parse(e.target.value as string),
          subscriptionWithVersion,
        );
      }
    : undefined;

  return (
    <div className={classes.root}>
      <ControlledComponentAndResourceEntityBreadcrumb
        entity={initialEntity.current}
        includeLast={false}
        compact
        isExpandable={false}
      />
      <Typography variant="body1" color="secondary">
        <span className={classes.breadcrumbDivider}>/</span>
      </Typography>
      {productVersionChildren.length !== 0 && (
        <FormControl
          variant="outlined"
          size="small"
          className={classes.formControl}
        >
          <Select
            value={JSON.stringify(entity)}
            renderValue={renderSelectValue}
            onChange={onSelectChange}
            MenuProps={{
              getContentAnchorEl: null,
            }}
          >
            {productVersionChildren.map(renderSelectOption)}
          </Select>
        </FormControl>
      )}
    </div>
  );
};
